import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

// Contact forms
const ComplaintsForm = loadable(() => import('/src/forms/Contact/ComplaintsForm'))
const ExistingCustomersForm = loadable(() => import('/src/forms/Contact/ExistingCustomersForm'))
const MarketingOptInForm = loadable(() => import('/src/forms/MarketingOptInForm'))
const MarketingOptOutForm = loadable(() => import('/src/forms/MarketingOptOutForm'))
const PolicyCancellationForm = loadable(() => import('/src/forms/Contact/PolicyCancellationForm'))
const PressPartnershipsForm = loadable(() => import('/src/forms/Contact/PressPartnershipsForm'))
const PRPortalForm = loadable(() => import('/src/forms/Contact/PRPortalForm'))
const TechnicalEnquiryForm = loadable(() => import('/src/forms/Contact/TechnicalEnquiryForm'))
// Careers forms
const CareersReferForm = loadable(() => import('/src/forms/Careers/ReferForm'))
const CareersReferredForm = loadable(() => import('/src/forms/Careers/ReferredForm'))
// Other forms
const AccessibilityForm = loadable(() => import('/src/forms/AccessibilityForm'))
const ClaimVoucherForm = loadable(() => import('/src/forms/ClaimVoucherForm'))
const CustomerFeedbackForm = loadable(() => import('/src/forms/CustomerFeedbackForm'))
const Over50sGuideForm = loadable(() => import('/src/forms/Over50sGuideForm'))

const FormSelector = ({ form, disclaimer }) => {
    const forms = {
        ExistingCustomersForm,
        ComplaintsForm,
        PolicyCancellationForm,
        PressPartnershipsForm,
        PRPortalForm,
        TechnicalEnquiryForm,
        MarketingOptOutForm,
        MarketingOptInForm,
        Over50sGuideForm,
        CustomerFeedbackForm,
        ClaimVoucherForm,
        AccessibilityForm,
        CareersReferForm,
        CareersReferredForm
    }

    const Form = forms[form]

    return <Form disclaimer={disclaimer} />
}

FormSelector.propTypes = {
    /**
     * Form name
     */
    form: PropTypes.string.isRequired,
    /**
     * Form disclaimer text
     */
    disclaimer: PropTypes.string
}

export default FormSelector
